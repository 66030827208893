.container {
    padding: 4rem;
}

.container-title {
    padding-bottom: 2rem;
}

.div-map {
    display: flex;
    justify-content: center; /* Center the child elements horizontally */
    align-items: center; /* Center the child elements vertically */
    /*height: 1000px;*/ /* Set the height as needed */
}

    .div-map img {
        max-width: 100%; /* Make sure the image doesn't exceed the container width */
        max-height: 100%; /* Make sure the image doesn't exceed the container height */
    }

.map-info {
    margin-top: 20px; /* Add 20px space above */
    text-align: center; /* Center the content horizontally */
}