/* classe para adicionar margem direita */
.btn-margin-right {
    margin-right: 8px; /* altere o valor conforme necess�rio */
}

/* classe para adicionar margem inferior */
.btn-margin-bottom {
    margin-bottom: 8px; /* altere o valor conforme necess�rio */
}

/* classe para adicionar margem entre os bot�es */
.btn-group-margin {
    margin-right: 8px; /* altere o valor conforme necess�rio */
}

.title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

    .title::after {
        content: "";
        display: block;
        width: 100px;
        height: 3px;
        background-color: #007bff;
        margin: 10px auto;
    }

/* UpdatePhotos.css */
.subtitle {
    font-size: 24px;
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

    .subtitle::after {
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        background-color: #007bff;
        margin: 10px auto;
    }

/* UpdatePhotos.css */
.photo-container {
    padding: 8px; /* Espa�amento entre a borda e a imagem */
}

    .photo-container .border {
        width: 200px; /* Largura da borda */
        height: 200px; /* Altura da borda */
    }

    .photo-container img {
        width: 100%; /* Ajuste para ocupar todo o espa�o dentro da borda */
        height: auto; /* Para manter a propor��o da imagem */
    }

/* AddRemovePhotos.css */
/* Ajuste o tamanho das checkboxes */
.checkbox-container input[type="checkbox"] {
    width: 20px; /* largura desejada */
    height: 20px; /* altura desejada */
}
