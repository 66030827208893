.car-page {
    display: flex;
    align-items: center;
    padding: 40px 20px 20px 20px;
}

.car-photo {
    flex: 1;
    margin-right: 20px;
    max-width: 700px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    border-radius: 10px; /* Defina o raio desejado */
    /*background-color: #ccc;*/
    padding: 20px;
}

    .car-photo img {
    }

.car-info {
    flex: 2;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.brand {
    font-size: 24px;
    font-weight: bold;
}

.model {
    font-size: 16px;
}

.price {
    font-size: 24px;
    color: #808080;
}

.separator {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 30px 0;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

/*.feature {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
}
*/
/*.features-grid {
    display: grid;
    grid-template-columns: 1fr;*/ /* A única coluna da grade */
    /*gap: 10px;*/ /* Espaçamento entre células */
/*}*/

.spec {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.icon-container {
    margin-right: 10px; /* Espaçamento à direita do ícone */
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.spec-separator-text {
    margin: 20px 0;
}

.edit-button {
    border: 2px solid #007bff;
    border-radius: 10px; /* Cantos arredondados */
    padding: 10px 15px;
    text-align: center;
    font-size: 16px;
    color: #007bff;
    background-color: transparent;
    cursor: pointer;
    position: fixed;
    margin-top: 20px;
    top: 7.5%;
    right: 150px; /* Ajuste a margem direita conforme necessário */
    transform: translateY(-50%);
}

.edit-photo-button {
    border: 2px solid #007bff;
    border-radius: 10px; /* Cantos arredondados */
    padding: 10px 15px;
    text-align: center;
    font-size: 16px;
    color: #007bff;
    background-color: transparent;
    cursor: pointer;
    position: fixed;
    margin-top: 20px;
    top: 7.5%;
    right: 263px; /* Ajuste a margem direita conforme necessário */
    transform: translateY(-50%);
}

.export-fb {
    border: 2px solid #007bff;
    border-radius: 10px; /* Cantos arredondados */
    padding: 10px 15px;
    text-align: center;
    font-size: 16px;
    color: #007bff;
    background-color: transparent;
    cursor: pointer;
    position: fixed;
    margin-top: 20px;
    top: 7.5%;
    right: 420px; /* Ajuste a margem direita conforme necessário */
    transform: translateY(-50%);
}

.edit-icon {
    margin-right: 5px;
}

.archive-button {
    border: 2px solid #007bff;
    border-radius: 10px; /* Cantos arredondados */
    padding: 10px 15px;
    text-align: center;
    font-size: 16px;
    color: #007bff;
    background-color: transparent;
    cursor: pointer;
    position: fixed;
    margin-top: 20px;
    top: 7.5%;
    right: 20px; /* Ajuste a margem direita conforme necessário */
    transform: translateY(-50%);
}

.archive-icon {
    margin-right: 5px;
}


@media (max-width: 1250px) {
    .car-page {
        flex-direction: column; /* Stack items vertically */
        align-items: stretch;
    }

    .car-photo {
        margin-right: 0; /* Remove right margin when stacked */
        max-width: 100%;
    }
}

/* Add a media query for screens below 650px */
@media (max-width: 650px) {
    .features-grid {
        grid-template-columns: repeat(2, 1fr); /* Change to 2 columns for smaller screens */
    }
}

/* You can add more media queries for smaller screens if needed */
@media (max-width: 480px) {
    .features-grid {
        grid-template-columns: 1fr; /* Change to 1 column for even smaller screens */
    }
}