.navbar {
    background: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    border-bottom: 3px solid #990000;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1920px;
}

.logo-svg {
    width: 100px; /* Defina a largura desejada */
    height: auto; /* Defina a altura para "auto" para manter a propor��o original */
}


.navbar-logo {
    color: coral;
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    max-width: 100%; /* Remove the specific max-width value */
    overflow: hidden; /* Prevent text overflow */
    white-space: nowrap; /* Keep the text on a single line */
}

.navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    /*transform: translate(25%, 50%);*/
    transform: translate(80%, -15%);
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 95vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-menu-admin {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 95vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-item {
    height: 80px;
}

.nav-links {
    color: #990000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

    .nav-links:hover {
        border-bottom: 4px solid #c10000;
        transition: all 0.2s ease-out;
        color: #c10000;
    }

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 980px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

        .nav-menu.active {
            background: #242222;
            left: 0;
            opacity: 0.99;
            transition: all 0.5s ease;
            z-index: 1;
        }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

        .nav-links:hover {
            background-color: #fff;
            border-radius: 0;
        }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

        .nav-links-mobile:hover {
            background: #fff;
            transition: 250ms;
        }
}
