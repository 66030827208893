/* PolicyPage.css */

/* Estilos para a p�gina */
.policy-page {
    padding: 4rem;
    text-align: center; /* Centraliza o conte�do */
}

/* Estilos para o conte�do */
.policy-content {
    max-width: 800px; /* Largura m�xima do conte�do */
    margin: 0 auto; /* Centraliza o conte�do horizontalmente */
    text-align: left; /* Alinha o texto � esquerda */
}

/* Estilos para os cabe�alhos */
h1 {
    font-size: 32px;
    margin-bottom: 20px;
}

h2 {
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 10px;
}

h3 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
}

/* Estilos para a lista com marcadores */
ul {
    list-style-type: none; /* Remove os marcadores padr�o */
    padding-left: 0; /* Remove o espa�o � esquerda da lista */
}

/* Estilos para os bullets (marcadores) */
.bullet {
    font-size: 16px;
    margin-right: 5px; /* Espa�o entre o bullet e o texto */
    color: #007bff; /* Cor do bullet (azul) */
}
