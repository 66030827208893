/*.hero-container {
    background: url('../../public/images/static/background.png') center top no-repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background-size: cover;*/ /* Ajustado para cover */
    /*background-position: center top;*/ /* Ajustado para garantir que a imagem esteja sempre no topo */
    /*margin: 0;
    min-height: 100vh;
}*/

    /* Se desejar um espa�amento m�nimo abaixo da imagem */
    /*.hero-container:after {
        content: "";
        flex-grow: 1;
        min-height: 1px;*/ /* Adicione uma altura m�nima para garantir visibilidade */
    /*}

    .hero-container > h1 {
        color: #fff;
        font-size: 100px;
        margin-top: -100px;
    }

    .hero-container > p {
        margin-top: 8px;
        color: #fff;
        font-size: 32px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }

.hero-btns {
    margin-top: 32px;
}

    .hero-btns .btn {
        margin: 6px;
    }

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width: 960px) {
    .hero-container {
        min-height: 50vh;*/ /* Ajuste a porcentagem conforme necess�rio */
    /*}

    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container {
        min-height: 25vh;*/ /* Ajuste a porcentagem conforme necess�rio */
    /*}

    .hero-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container > p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .hero-container .btn {
        width: 100%;
    }
}*/

.landing-page {
    width: 100%;
    overflow: hidden;
}

    .landing-page img {
        width: 100%;
        height: auto;
        display: block;
    }