.search-box-horizontal {
    display: flex;
    text-align: center;
    max-width: 1120px;
    width: 90%;
    align-items: center;
    flex-flow: column;
    margin: 0 auto;
    padding: 0 10px 50px 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.search-inputs-wrapper {
    margin: 50px 0 0;
    margin-bottom: 20px;
}

.search-inputs-horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.hidden {
    display: none;
}

.search-field {
    margin-right: 5px;
}

.left-half-search-field {
    margin-right: 2px;
}

.right-half-search-field {
    margin-right: 7px;
}

    .left-half-search-field input,
    .right-half-search-field input {
        width: 125px;
    }

.search-button-horizontal {
    margin-top: 45px;
    border: none;
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
}

@media screen and (max-width: 1300px) {
    .search-inputs-horizontal {
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Alinhe � esquerda para manter os labels alinhados */
        position: relative;
    }

    .search-inputs-wrapper {
        margin: 40px 0 0;
        margin-bottom: 10px;
    }

    .search-field, .right-half-search-field {
        margin-bottom: 20px; /* Espa�amento extra entre elementos n�o em pares */
    }

        /* Para alinhar labels � direita (opcional) */
        .search-field label {
            text-align: right;
        }

    .half-search-field input {
        width: 100px;
    }

    .left-half-search-field input,
    .right-half-search-field input {
        width: 250px;
    }
}

/* Dropdown styles */
.search-dropdown {
    position: relative; /* Set to relative */
    width: 250px; /* Same width as the input */
}

    /* Styled dropdown trigger */
    .search-dropdown .dropdown-trigger {
        cursor: pointer;
        display: block;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    /* Dropdown options container */
    .search-dropdown .dropdown-options {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: none;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 5px 5px;
        background-color: #fff;
        max-height: 150px; /* Set a fixed height */
        overflow-y: auto; /* Add scrollbar if content exceeds the height */
        z-index: 1; /* Set a higher z-index value */
    }


    /* Show dropdown options when hovering over the trigger */
    .search-dropdown:hover .dropdown-options {
        display: block;
    }

    /* Styling for the dropdown options */
    .search-dropdown .dropdown-option {
        padding: 10px;
        cursor: pointer;
    }

        .search-dropdown .dropdown-option:hover {
            background-color: #f0f0f0;
        }

.maxHeightDropdown {
    max-height: 150px;
    overflow-y: auto;
}

@media screen and (max-width: 510px) {
    .left-half-search-field input, .right-half-search-field input {
        width: 140px;
    }

    .search-dropdown {
        width: 140px;
    }
}
