* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /*font-family: 'PT Sans', sans-serif;*/
    font-family: 'Muli', Arial,Helvetica,sans-serif;
}
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }

.services {
    /*background-image: url('/images/img-2.jpg');*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
}

.products {
    /*background-image: url('/images/img-1.jpg');*/
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
}

.sign-up {
    /*background-image: url('/images/img-2.jpg');*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
}

.home-wrapper {
    /*margin-top: -240px;*/
}