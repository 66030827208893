.container {
    padding-bottom: 50px;
}

.result {
    display: flex;
    flex: 2; /* Take up 50% of the container width */
    flex-direction: column;
    align-items: center;
    height: 200px;
}

.center-content {
    text-align: center;
}

/* Header Title Styles */
    .header-title {
    font-size: 32px; /* Adjust the font size as needed */
    font-weight: bold;
    margin-bottom: 20px; /* Adjust the bottom margin as needed */
    color: #333; /* Change the color to your preference */
}

/* Header Styles */
.header {
    flex: 1; /* Take up 50% of the container width */
    padding-right: 20px; /* Add spacing between header and form */
}

/* Form Styles */
.advert-form {
    flex: 2; /* Take up 50% of the container width */
}

/*button*/

.centered-buttons {
    margin-top: 20px; /* Adjust as needed */
}

.blue-button, .green-button {
    display: inline-block;
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 0 2px 2px;
    width: 300px;
    text-align: center;
}

.blue-button {
    background-color: blue;
    color: white;
}

    .blue-button:hover {
        background-color: darkblue;
    }

.green-button {
    background-color: green;
    color: white;
}

    .green-button:hover {
        background-color: darkgreen;
    }