.dropdown-container {
    display: flex;
    text-align: right;
    margin-bottom: 20px;
    max-width: 1120px;
    width: 90%;
    flex-flow: column;
    margin: 0 auto;
    padding-top: 35px;
}

/* Estilo para a dropdown */
.dropdown {
    position: absolute;
    top: 0;
    right: 0;
}

/* Estilo para o bot�o da dropdown */
.dropdown-toggle {
    background-color: white;
    border: 1px solid #007bff; /* Blue border */
    color: #007bff; /* Blue text color */
    border-radius: 4px;
}

    /* Estilo para o �cone de seta (� direita) */
    .dropdown-toggle::after {
        content: '\f107'; /* C�digo Unicode para uma seta para baixo (FontAwesome) */
        font-family: FontAwesome;
        margin-left: 10px;
        font-size: 12px;
    }

    /* Estilo para o item selecionado */
    .dropdown-toggle.selected {
        background-color: #007bff;
        color: white;
    }

/* Estilo para o menu suspenso */
.dropdown-menu {
    right: 0; /* Align the dropdown to the right */
    left: auto; /* Reset the left position */
    min-width: 100%; /* Make the dropdown as wide as the container */
    border: 1px solid #007bff; /* Blue border */
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional box shadow */
    position: absolute; /* Add this to use absolute positioning */
}

/* Estilo para os itens do menu suspenso */
.dropdown-item {
    color: #007bff; /* Blue text color */
}

    /* Estilo para os itens do menu suspenso quando hover */
    .dropdown-item:hover {
        background-color: #007bff; /* Blue background on hover */
        color: white; /* White text color on hover */
    }
